<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input
                placeholder="请输入会员编号"
                v-model="params.MemberCode"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="提现账号:" prop="BankCard">
              <Input
                placeholder="请输入提现账号"
                v-model="params.BankCard"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="状态:" prop="Status">
              <Select clearable v-model="params.Status" placeholder="状态">
                <Option
                  v-for="(item, index) in bonusDrawStatusEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="日期范围:" prop="TempDate">
              <Date-picker
                style="width: 100%"
                clearable
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                placement="bottom-end"
                placeholder="日期范围"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="dateUpdate"
              ></Date-picker>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            class="ml2"
            icon="ios-share-outline"
            @click="ExportInfo"
          >
            导出
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-selection-change="SelectionClick"
      @on-sort-change="SortChange"
      stripe
    >
      <template slot="Image" slot-scope="{ row }">
        <Tooltip placement="right">
          <Avatar
            :src="$setImagePrefix(row.PayPic, '')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.PayPic, '')"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>

      <template slot="Action" slot-scope="{ row }">
        <Button
          size="small"
          type="success"
          @click="openDrawer(row)"
          v-if="row.Status == 10 && $canAction(['BonusDraw_Deal'])"
        >
          支付
        </Button>
        <Button
          class="ml2"
          size="small"
          type="warning"
          @click="rejectDrawer(row)"
          v-if="row.Status == 10 && $canAction(['BonusDraw_Deal'])"
        >
          驳回
        </Button>
        <Button
          class="ml2"
          size="small"
          type="primary"
          v-if="$canAction(['BonusDraw_Deal'])"
          @click="editDrawer(row)"
        >
          编辑
        </Button>
        <Button
          size="small"
          class="ml2"
          type="error"
          v-if="$canAction(['BonusDraw_Deal--'])"
          @click="handleOpenDelete(row)"
        >
          删除
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <!--    用户信息抽屉-->
    <UserProfileDrawer ref="userDrawer" />
    <!--    支付抽屉-->
    <ReflectPayDrawer ref="drawPayDrawer" @on-save="onUpdated" />
    <!--    驳回抽屉-->
    <ReflectRejectDrawer ref="drawRejectDrawer" @on-save="onUpdated" />
    <!--    编辑抽屉-->
    <ReflectEditDrawer ref="drawEditDrawer" @on-save="onUpdated" />
  </Layout>
</template>

<script>
import api from "@/api";
import bizEnum from "@/libs/bizEnum";
import Layout from "@/components/layout/index.vue";
import UserProfileDrawer from "../member/components/member-drawer.vue";
import ReflectPayDrawer from "./components/draw-pay.vue";
import ReflectRejectDrawer from "./components/draw-reject.vue";
import ReflectEditDrawer from "./components/draw-edit.vue";
export default {
  name: "BonusDraw",
  data() {
    return {
      TempDate: [],
      tableLoading: false,
      tableHeight: 0,
      RowID: 0,
      BonusLoading: false,
      selection: [],
      params: {
        MemberCode: "",
        BankUserName: "",
        Status: "",
        Page: 1,
        PageSize: 40,
        DateBegin: "",
        DateEnd: "",
        BonusType: 10,
        SortName: "DrawDate",
        SortOrder: "Desc",
      },
      columns: [
        { type: "selection", width: 58, align: "center" },
        { title: "凭证", key: "PayPic", slot: "Image", minWidth: 80 },
        {
          title: "会员编号",
          sortable: "custom",
          key: "MemberCode",
          slot: "MemberView",
          minWidth: 100,
        },
        { title: "会员昵称", key: "MemberName", minWidth: 80 },
        { title: "所属一级代理", key: "MemberOne", minWidth: 150 },
        { title: "提现金额", sortable: "custom", key: "Amount", minWidth: 90 },
        { title: "状态", key: "StatusCN", minWidth: 60 },
        {
          title: "申请时间",
          sortable: "custom",
          key: "DrawDate",
          minWidth: 135,
        },
        {
          title: "处理时间",
          sortable: "custom",
          key: "OperationDate",
          minWidth: 135,
        },
        { title: "处理人", key: "Operater", minWidth: 90 },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      showUserProfileDrawer: false,
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  computed: {
    bonusDrawStatusEnum() {
      return bizEnum.BonusDrawStatusEnum;
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "right",
        fixed: "right",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
      });
    }
    this.loadBonusReflectList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        self.loadBonusReflectList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadBonusReflectList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadBonusReflectList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadBonusReflectList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadBonusReflectList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    async loadBonusReflectList() {
      const _this = this;
      this.tableLoading = true;
      try {
        const res = await api.GetBonusDrawList(this.params);
        res.Data.DataList.forEach((item) => {
          if (_this.selection.findIndex((it) => it.ID === item.ID) >= 0) {
            item._checked = true;
          } else {
            item._checked = false;
          }
        });
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    // 选项发生改变方法
    SelectionClick(selection) {
      const _this = this;
      let nowSel = _this.selection.map((item) => {
        return { ID: item.ID };
      });
      nowSel.map((item) => {
        if (
          _this.resObj.DataList.findIndex((it) => it.ID === item.ID) >= 0 &&
          selection.findIndex((bt) => bt.ID === item.ID) < 0
        ) {
          _this.selection.splice(
            _this.selection.findIndex((ct) => ct.ID === item.ID),
            1
          );
        }
      });
      selection.map((item) => {
        if (_this.selection.findIndex((it) => it.ID === item.ID) < 0) {
          _this.selection.push(item);
        }
      });
    },
    exportData() {
      const _this = this;
      if (this.selection.length === 0) {
        this.$Message.error("请先选择要导出的行");
        return;
      }
      let colDatas = JSON.parse(JSON.stringify(_this.selection));
      colDatas.forEach((it) => {
        it.BankCard = '="' + it.BankCard + '"';
        it.DrawDate = '="' + it.DrawDate + '"';
      });
      _this.$refs.dataTable.exportCsv({
        filename: "奖金提现列表",
        columns: _this.columns.filter(
          (col, index) => index > 1 && index !== 11
        ),
        data: colDatas, // _this.BonusReflect.List.filter((data, index) => _this.selection.indexOf(data.ID) !== -1)
      });
      _this.selection = [];
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadBonusReflectList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadBonusReflectList();
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.userDrawer.obj = res.Data;
          this.$refs.userDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    openDrawer(row) {
      this.$refs.drawPayDrawer.$refs.form.resetFields();
      this.$refs.drawPayDrawer.obj = JSON.parse(JSON.stringify(row));
      this.$refs.drawPayDrawer.isShow = true;
      this.$refs.drawPayDrawer.isEdit = true;
    },
    rejectDrawer(row) {
      this.$refs.drawRejectDrawer.obj = row;
      this.$refs.drawRejectDrawer.isShow = true;
      this.$refs.drawRejectDrawer.isEdit = true;
    },
    editDrawer(row) {
      this.$refs.drawEditDrawer.obj = row;
      this.$refs.drawEditDrawer.isShow = true;
      this.$refs.drawEditDrawer.isEdit = true;
    },
    async handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除这条记录吗？",
        loading: true,
        onOk: function () {
          _this.Delete(row);
        },
      });
    },
    // 远程排序
    SortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadBonusReflectList();
    },
    async Delete(row) {
      try {
        const res = await api.DeleteBonusReflect({
          id: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          this.resObj.DataList.splice(index, 1);
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.loadBonusReflectList();
      });
    },
    ExportInfo() {
      let filename = `奖金提现列表.xls`;
      this.params.exportName = filename;
      api.ExportBonusDrawList(this.params);
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
  },
  components: {
    UserProfileDrawer,
    ReflectPayDrawer,
    ReflectRejectDrawer,
    ReflectEditDrawer,
    Layout,
  },
};
</script>
